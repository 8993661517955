<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="60%"
      scrollable
    >
      <v-card>
        <v-card-title>
          Modificar desagregos por Local y Periodo
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="">
          <v-form ref="form">
            <!-- Canal, Región y Empresa -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-8">
              <v-col cols="12" sm="4" md="4" class="py-0">
                <v-autocomplete
                  v-model="selectedCanal"
                  label="Canal"
                  item-text="nombre"
                  :items="canales"
                  return-object
                  clearable
                  outlined
                  dense
                  @change="setearProvincias()"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="py-0">
                <v-autocomplete
                  v-model="selectedRegion"
                  label="Región"
                  item-text="nombre"
                  :items="regiones"
                  return-object
                  clearable
                  outlined
                  dense
                  @change="setearProvincias()"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="py-0">
                <v-autocomplete
                  v-model="selectedEmpresa"
                  label="Empresa"
                  item-text="nombre_corto"
                  :items="empresas"
                  return-object
                  clearable
                  outlined
                  dense
                  @change="setearProvincias()"
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- Sucursal, Importe, Mes y Cantidad de Meses -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
              <v-col cols="12" sm="3" md="3" class="py-0">
                <v-autocomplete
                  v-model="selectedSucursal"
                  label="Sucursal"
                  item-text="nombre"
                  :items="sucursales"
                  return-object
                  clearable
                  outlined
                  dense
                  @change="setearProvincias()"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-0">
                <v-text-field
                  v-model="importe"
                  label="Importe"
                  type="number"
                  prefix="$"
                  outlined
                  dense
                  :disabled="ptovtas.length > 1"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-0">
                <PeriodoPicker
                  v-model="periodo"
                  :label="true"
                  :fecha_minima="moment(new Date()).subtract(5, 'months').format('YYYY-MM')"
                />
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-0">
                <v-text-field
                  v-model="cantMeses"
                  label="Cant. Meses"
                  type="number"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Puntos de Venta -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
              <v-col cols="12" class="py-0">
                <v-autocomplete
                  v-model="ptovtas"
                  label="Puntos de venta"
                  item-value="id"
                  item-text="nombre"
                  :items="locales"
                  clearable
                  outlined
                  dense
                  multiple
                  hide-details
                  chips
                  deletable-chips
                  return-object
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- Botones -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
              <v-col cols="12" class="d-flex justify-center py-0">
                <v-btn
                  color="success"
                  small
                  @click="agregar()"
                >
                  <v-icon small left>fas fa-plus</v-icon>
                  Agregar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-data-table
            class="elevation-2 pt-4"
            :headers="headers"
            :items="desagregos"
            item-key="local_id"
            :items-per-page="-1"
            hide-default-footer
            dense
          >
            <template
              v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ header.formatter(value) }}
            </template>
            <!-- Meses -->
            <!-- <template v-slot:[`item.meses`]="{ item }">
              <v-text-field 
                :id="mesesRef"
                :ref="mesesRef"
                v-model="item.meses" 
                type="number" 
                outlined 
                dense 
                hide-details
                @blur="calcularSubTotales(item, 0)"
                @keypress.native.enter="$refs[mesesRef].blur()"
              ></v-text-field>
            </template> -->
            <!-- Porcentaje -->
            <template v-slot:[`item.porcentaje`]="{ item }">
              <v-text-field 
                :id="porcRef"
                :ref="porcRef"
                v-model="item.porcentaje" 
                prefix="%" 
                type="number" 
                outlined 
                dense 
                hide-details
                @blur="calcularSubTotales(item, 1)"
                @keypress.native.enter="$refs[porcRef].blur()"
              ></v-text-field>
            </template>
            <!-- Importe -->
            <template v-slot:[`item.importe`]="{ item }">
              <v-text-field 
                :id="impRef"
                :ref="impRef"
                v-model="item.importe" 
                prefix="$" 
                type="number" 
                outlined 
                dense 
                hide-details
                @blur="calcularSubTotales(item, 2)"
                @keypress.native.enter="$refs[impRef].blur()"
              ></v-text-field>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                title="Eliminar"
                color="error"
                small
                @click.stop="eliminar(item)"
              >
                fas fa-trash
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-alert
                class="mx-auto mt-4"
                max-width="300"
                type="warning"
                border="left"
                dense
                text
              >
                No hay desagregos
              </v-alert>
            </template>
            <!-- finlas de totales -->
            <template slot="body.append">
              <tr class="rowsel">
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th style="font-size: 14px; font-weight: 600; border-style: groove  none none none">Total Porcentaje</th>
                <th style="font-size: 14px; font-weight: 600; border-style: groove  none none none" class="text-right">
                  {{ formatMoney(totalPorcentaje) }}
                </th>
                <th style="border-style: groove  none none none"></th>
              </tr>
            </template>
            <template slot="body.append">
              <tr class="rowsel">
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th style="font-size: 14px; font-weight: 600; border-style: groove  none none none">Total desagregado</th>
                <th style="font-size: 14px; font-weight: 600; border-style: groove  none none none" class="text-right">
                  {{ formatMoney(total) }}
                </th>
                <th style="border-style: groove  none none none"></th>
              </tr>
            </template>
            <template slot="body.append">
              <tr class="">
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th style="font-size: 14px; font-weight: 400">Total de la transacción </th>
                <th style="font-size: 14px; font-weight: 400" class="text-right">
                  {{ formatMoney(caja.total) }}
                </th>
                <th></th>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="error"
            class="mr-2"
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            @click="confiirmar()"
          >
            <v-icon left>fas fa-check</v-icon>
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { format_money, objetoNoVacio, cadenaVacia, roundNumber } from '../../util/utils'
import PeriodoPicker from '../util/PeriodoPicker.vue'
export default {
  data() {
    return {
      formatMoney: format_money,
      moment: moment,
      total: 0,
      totalPorcentaje: 0,
      ptovtas: [],
      locales: [],
      importe: '',
      desagregos: [],
      headers: [
        { text: 'Periodo', value: 'periodo', sortable: false },
        { text: 'Punto de venta', value: 'local_nombre', sortable: false },
        { text: 'Meses', value: 'meses', align: 'end', sortable: false },
        { text: 'Importe Mensual', value: 'importe_mensual', align: 'end', sortable: false, formatter: format_money },
        { text: 'Porcentaje', value: 'porcentaje', align: 'end', sortable: false },
        { text: 'Importe', value: 'importe', align: 'end', sortable: false, formatter: format_money },
        { text: 'Acciones', value: 'actions', align: 'center',sortable: false }
      ],
      caja: {},
      selectedRegion: {},
      selectedCanal: {},
      selectedSucursal: {},
      selectedEmpresa: {},
      periodo: '',
      cantMeses: '',
      mesesRef: 'mesesRef',
      porcRef: 'porcRef',
      impRef: 'impRef'
    }
  },
  props: {
    value: Boolean,
    p_caja: Object
  },
  components: {
    PeriodoPicker,
  },
  computed: {
    ...mapState('controlCaja', ['tipos_comp', 'tipificaciones']),
    ...mapState(['empresas', 'sucursales', 'all_locales', 'canales', 'regiones']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    setearProvincias(){
      if (this.selectedCanal == null || this.selectedCanal == undefined) this.selectedCanal = {}
      if (this.selectedRegion == null || this.selectedRegion == undefined) this.selectedRegion = {}
      if (this.selectedEmpresa == null || this.selectedEmpresa == undefined) this.selectedEmpresa = {}
      if (this.selectedSucursal == null || this.selectedSucursal == undefined) this.selectedSucursal = {}
      // llenamos los ptos de vta de acuerdo a los filtros ingresados
      this.ptovtas = []
      this.locales = []
      if (!objetoNoVacio(this.selectedCanal) && objetoNoVacio(this.selectedRegion) && !objetoNoVacio(this.selectedEmpresa) && !objetoNoVacio(this.selectedSucursal)){
        // solo por región, lleno ambos listados
        this.locales = this.all_locales.filter(element => element.region_id == this.selectedRegion.codigo || element.region_id == 0)
        this.ptovtas = this.all_locales.filter(element => element.region_id == this.selectedRegion.codigo || element.region_id == 0)
      }else if (!objetoNoVacio(this.selectedCanal) && !objetoNoVacio(this.selectedRegion) && objetoNoVacio(this.selectedEmpresa) && !objetoNoVacio(this.selectedSucursal)){
        // solo por empresa, solo lleno el listado disponible
        this.locales = this.all_locales.filter(element => element.empresa == this.selectedEmpresa.id)
      }else if (!objetoNoVacio(this.selectedCanal) && !objetoNoVacio(this.selectedRegion) && !objetoNoVacio(this.selectedEmpresa) && objetoNoVacio(this.selectedSucursal)){
        // solo por sucursal, solo lleno el listado disponible
        this.locales = this.all_locales.filter(element => element.sucursal == this.selectedSucursal.id)
      }else if (objetoNoVacio(this.selectedCanal) && !objetoNoVacio(this.selectedRegion) && !objetoNoVacio(this.selectedEmpresa) && !objetoNoVacio(this.selectedSucursal)){
        // solo por canal, lleno el listado disponible
        this.locales = this.all_locales.filter(element => element.canal == this.selectedCanal.id)
      }else if (objetoNoVacio(this.selectedCanal) && objetoNoVacio(this.selectedRegion) && !objetoNoVacio(this.selectedEmpresa) && !objetoNoVacio(this.selectedSucursal)){
        // por canal y región
        this.locales = this.all_locales.filter(element => element.canal == this.selectedCanal.id && (element.region_id == this.selectedRegion.codigo || element.region_id == 0))
        this.ptovtas = this.all_locales.filter(element => element.canal == this.selectedCanal.id && (element.region_id == this.selectedRegion.codigo || element.region_id == 0))
      }else if (objetoNoVacio(this.selectedCanal) && !objetoNoVacio(this.selectedRegion) && objetoNoVacio(this.selectedEmpresa) && !objetoNoVacio(this.selectedSucursal)){
        // por canal y empresa, lleno unicamente el listado disponible
        this.locales = this.all_locales.filter(element => element.canal == this.selectedCanal.id && element.empresa == this.selectedEmpresa.id)
      }else if (objetoNoVacio(this.selectedCanal) && !objetoNoVacio(this.selectedRegion) && !objetoNoVacio(this.selectedEmpresa) && objetoNoVacio(this.selectedSucursal)){
        // por canal y sucursal
        this.locales = this.all_locales.filter(element => element.canal == this.selectedCanal.id && element.sucursal == this.selectedSucursal.id)
      }else if (!objetoNoVacio(this.selectedCanal) && objetoNoVacio(this.selectedRegion) && objetoNoVacio(this.selectedEmpresa) && !objetoNoVacio(this.selectedSucursal)){
        // por región y empresa
        this.locales = this.all_locales.filter(element => element.empresa == this.selectedEmpresa.id && (element.region_id == this.selectedRegion.codigo || element.region_id == 0))
        this.ptovtas = this.all_locales.filter(element => element.empresa == this.selectedEmpresa.id && (element.region_id == this.selectedRegion.codigo || element.region_id == 0))
      }else if (!objetoNoVacio(this.selectedCanal) && objetoNoVacio(this.selectedRegion) && !objetoNoVacio(this.selectedEmpresa) && objetoNoVacio(this.selectedSucursal)){
        // por región y sucursal
        this.locales = this.all_locales.filter(element => (element.region_id == this.selectedRegion.codigo || element.region_id == 0) && element.sucursal == this.selectedSucursal.id)
        this.ptovtas = this.all_locales.filter(element => (element.region_id == this.selectedRegion.codigo || element.region_id == 0) && element.sucursal == this.selectedSucursal.id)
      }else if (!objetoNoVacio(this.selectedCanal) && !objetoNoVacio(this.selectedRegion) && objetoNoVacio(this.selectedEmpresa) && objetoNoVacio(this.selectedSucursal)){
        // por empresa y sucursal
        this.locales = this.all_locales.filter(element => element.empresa == this.selectedEmpresa.id && element.sucursal == this.selectedSucursal.id)
      }else if (objetoNoVacio(this.selectedCanal) && objetoNoVacio(this.selectedRegion) && objetoNoVacio(this.selectedEmpresa) && !objetoNoVacio(this.selectedSucursal)){
        // por canal, región y empresa
        this.locales = this.all_locales.filter(element => element.canal == this.selectedCanal.id && (element.region_id == this.selectedRegion.codigo || element.region_id == 0) && element.empresa == this.selectedEmpresa.id)
        this.ptovtas = this.all_locales.filter(element => element.canal == this.selectedCanal.id && (element.region_id == this.selectedRegion.codigo || element.region_id == 0) && element.empresa == this.selectedEmpresa.id)
      }else if (objetoNoVacio(this.selectedCanal) && objetoNoVacio(this.selectedRegion) && !objetoNoVacio(this.selectedEmpresa) && objetoNoVacio(this.selectedSucursal)){
        // por canal, región y sucursal
        this.locales = this.all_locales.filter(element => element.canal == this.selectedCanal.id && (element.region_id == this.selectedRegion.codigo || element.region_id == 0) && element.sucursal == this.selectedSucursal.id)
        this.ptovtas = this.all_locales.filter(element => element.canal == this.selectedCanal.id && (element.region_id == this.selectedRegion.codigo || element.region_id == 0) && element.sucursal == this.selectedSucursal.id)
      }else if (objetoNoVacio(this.selectedCanal) && !objetoNoVacio(this.selectedRegion) && objetoNoVacio(this.selectedEmpresa) && objetoNoVacio(this.selectedSucursal)){
        // por canal, empresa y sucursal, solo lleno el listado disponible
        this.locales = this.all_locales.filter(element => element.canal == this.selectedCanal.id && element.empresa == this.selectedEmpresa.id && element.sucursal == this.selectedSucursal.id)
      }else if (!objetoNoVacio(this.selectedCanal) && objetoNoVacio(this.selectedRegion) && objetoNoVacio(this.selectedEmpresa) && objetoNoVacio(this.selectedSucursal)){
        // por region, empresa y sucursal, solo lleno el listado disponible
        this.locales = this.all_locales.filter(element => (element.region_id == this.selectedRegion.codigo || element.region_id == 0) && element.empresa == this.selectedEmpresa.id && element.sucursal == this.selectedSucursal.id)
        this.ptovtas = this.all_locales.filter(element => (element.region_id == this.selectedRegion.codigo || element.region_id == 0) && element.empresa == this.selectedEmpresa.id && element.sucursal == this.selectedSucursal.id)
      }else if (objetoNoVacio(this.selectedCanal) && objetoNoVacio(this.selectedRegion) && objetoNoVacio(this.selectedEmpresa) && objetoNoVacio(this.selectedSucursal)){
        // por todo
        this.locales = this.all_locales.filter(element => element.canal == this.selectedCanal.id && (element.region_id == this.selectedRegion.codigo || element.region_id == 0) && element.empresa == this.selectedEmpresa.id && element.sucursal == this.selectedSucursal.id)
        this.ptovtas = this.all_locales.filter(element => element.canal == this.selectedCanal.id && (element.region_id == this.selectedRegion.codigo || element.region_id == 0) && element.empresa == this.selectedEmpresa.id && element.sucursal == this.selectedSucursal.id)
      }
    },
    agregar(){
      if (this.ptovtas.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar algún punto de venta.',
          color: 'warning'
        })
        return
      }
      if (this.periodo == null || this.periodo == undefined || this.periodo.toString().length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar el Periodo.',
          color: 'warning'
        })
        return
      }
      if (this.cantMeses == null || this.cantMeses == undefined || this.cantMeses.toString().length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar la Cantidad de Meses.',
          color: 'warning'
        })
        return
      }
      if (this.desagregos.length > 0 && this.desagregos[0].meses != this.cantMeses){
        this.$store.dispatch('show_snackbar', {
          text: 'No se puede desagregar por cantidad de meses distintos.',
          color: 'warning'
        })
        return
      }
      if (this.desagregos.length > 0 && moment(this.desagregos[0].periodo).format("DD/MM/YYYY") != moment(`01/${this.periodo}`).format("DD/MM/YYYY")){
        this.$store.dispatch('show_snackbar', {
          text: 'No se puede desagregar por periodos distintos.',
          color: 'warning'
        })
        return
      }
      // controlo si es un solo elemento en desagregos
      if (this.ptovtas.length == 1 && (cadenaVacia(this.importe) || this.importe == 0)){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar el importe.',
          color: 'warning'
        })
        return
      }
      // controlo si es un elemento o más
      if (this.ptovtas.length == 1){
        // un solo elemento, debo agregarlo con sus cálculos completos
        let existe = this.desagregos.filter(element => element.local_id == this.ptovtas[0].id)
        if (existe.length > 0){
          this.$store.dispatch('show_snackbar', {
            text: 'Ya se encuentra en el listado el Punto de Venta seleccionado.',
            color: 'warning'
          })
          return
        }
        // armo mi objeto
        let unObj = {
          id: 0,
          periodo: `01/${this.periodo}`,
          local_id: this.ptovtas[0].id,
          local_nombre: this.ptovtas[0].nombre,
          porcentaje: 0,
          importe_mensual: 0,
          importe: this.importe,
          meses: this.cantMeses
        }
        // lo agrego a los desagregos y llamo al calculo de subtotales
        this.desagregos.push(unObj)
        this.calcularSubTotales(unObj, 2) // indico que es por importe
      }else{
        // me quedo unicamente con los locales que no se agregaron con anterioridad
        for (let id in this.ptovtas){
          let existe = this.desagregos.filter(element => element.local_id == this.ptovtas[id].id)
          if (existe.length == 0){
            this.desagregos.push({
              id: 0,
              periodo: `01/${this.periodo}`,
              local_id: this.ptovtas[id].id,
              local_nombre: this.ptovtas[id].nombre,
              porcentaje: 0,
              importe_mensual: 0,
              importe: 0,
              meses: this.cantMeses
            })
          }
        }
      }
      this.ptovtas = []
      this.importe = ''
      this.periodo = ''
      this.cantMeses = ''
    },
    eliminar(item){
      let pos = this.desagregos.indexOf(item)
      this.desagregos.splice(pos, 1)
      this.calcularTotales()
    },
    calcularSubTotales(item, opcion){
      let existe = this.desagregos.filter(element => element.local_id == item.local_id)
      let pos = this.desagregos.indexOf(existe[0])
      if (pos == -1){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la posición de este elemento en los desagregos.',
          color: 'warning'
        })
        return
      }
      if (cadenaVacia(this.desagregos[pos].meses) || this.desagregos[pos].meses == 0) this.desagregos[pos].meses = 1
      if (cadenaVacia(this.desagregos[pos].porcentaje)) this.desagregos[pos].porcentaje = 0
      if (cadenaVacia(this.desagregos[pos].importe_mensual)) this.desagregos[pos].importe_mensual = 0
      // obtengo el porcentaje total hasta el momento
      let porcTotal = this.desagregos.reduce((sum, porc) => sum + roundNumber(porc.porcentaje, 4), 0)
      if (opcion == 1){
        // por porcentaje
        if (roundNumber(porcTotal, 4) > 100 || roundNumber(porcTotal, 4) < 0){
          this.$store.dispatch('show_snackbar', {
            text: 'El porcentaje total no es válido. Intente poner otro valor para el mismo.',
            color: 'warning'
          })
          this.desagregos[pos].porcentaje = 0
          this.desagregos[pos].importe = 0
          this.desagregos[pos].importe_mensual = 0
          return
        }
        // porcentaje correcto, calculo el importe con respecto al total
        let porcLocal = roundNumber(roundNumber(this.desagregos[pos].porcentaje, 4) / 100, 4)
        this.desagregos[pos].importe = roundNumber(roundNumber(this.caja.total, 4) * porcLocal, 4)
        // controlo a ver si la suma de totales se pasa del total correcto
        let totalCalculado = this.desagregos.reduce((sum, porc) => sum + roundNumber(porc.importe, 4), 0)
        if (roundNumber(totalCalculado, 4) > roundNumber(this.caja.total, 4)) this.desagregos[pos].importe = this.desagregos[pos].importe - (roundNumber(totalCalculado, 4) - roundNumber(this.caja.total, 4))
      }else{
        // por importe mensual
        let porcLocal = roundNumber((roundNumber(this.desagregos[pos].importe, 4)*100)/roundNumber(this.caja.total, 4), 4)
        this.desagregos[pos].porcentaje = roundNumber(porcLocal, 4)
        // obtengo el nuevo porcentaje
        porcTotal = this.desagregos.reduce((sum, porc) => sum + roundNumber(porc.porcentaje, 4), 0)
        // se está pasando del 100% ?
        if (roundNumber(porcTotal, 4) > 100){
          this.$store.dispatch('show_snackbar', {
            text: 'Importe no válido ya que el porcentaje sobrepasa el 100%.',
            color: 'warning'
          })
          this.desagregos[pos].porcentaje = 0
          this.desagregos[pos].importe = 0
          this.desagregos[pos].importe_mensual = 0
          return
        }
      }
      // obtengo el importe mensual
      this.desagregos[pos].importe_mensual = roundNumber(this.desagregos[pos].importe/this.desagregos[pos].meses, 4)
      // paso a calcular los totales
      this.calcularTotales()
    },
    calcularTotales(){
      this.total = 0
      this.totalPorcentaje = 0
      for (let id in this.desagregos){
        this.total = this.total + roundNumber(this.desagregos[id].importe, 2)
        this.totalPorcentaje = this.totalPorcentaje + roundNumber(this.desagregos[id].porcentaje, 2)
      }
    },
    confiirmar(){
      if (this.desagregos.length > 0){
        // controlo si tengo filas con montos nulas o en cero
        let nulos = this.desagregos.filter(element => cadenaVacia(element.importe_mensual) || cadenaVacia(element.importe) || cadenaVacia(element.porcentaje) || element.importe_mensual == 0 || element.importe == 0 || element.porcentaje == 0)
        if (nulos.length > 0){
          this.$store.dispatch('show_snackbar', {
            text: 'Existen campos vacíos o en cero. Verificar.',
            color: 'warning'
          })
          return
        }
        // se llegó al 100%?
        let porcTotal = this.desagregos.reduce((sum, porc) => sum + roundNumber(porc.porcentaje, 2), 0)
        if (roundNumber(porcTotal, 2) != 100){
          this.$store.dispatch('show_snackbar', {
            text: 'Porcentaje final incorrecto. Verificar.',
            color: 'warning'
          })
          return
        }
        // se completó el total?
        let totalLocal = this.desagregos.reduce((sum, porc) => sum + roundNumber(porc.importe, 2), 0)
        if (roundNumber(totalLocal, 2) != roundNumber(this.caja.total, 2)){
          this.$store.dispatch('show_snackbar', {
            text: 'La suma de los importe no corresponde al total del movimiento de caja. Verificar.',
            color: 'warning'
          })
          return
        }
      }
      this.$emit('desagrego', this.desagregos)
      this.dialog = false
    }
  },
  watch: {
    dialog(val){
      if (val){
        this.caja = JSON.parse(JSON.stringify(this.p_caja))
        if (this.caja.desagregos){
          this.desagregos = JSON.parse(JSON.stringify(this.caja.desagregos))
          this.calcularTotales()
        }else this.desagregos = []
      }else{
        this.total = 0
        this.totalPorcentaje = 0
        this.ptovtas = []
        this.locales = []
        this.importe = ''
        this.desagregos = []
        this.caja = {}
        this.selectedRegion = {}
        this.selectedCanal = {}
        this.selectedSucursal = {}
        this.selectedEmpresa = {}
        this.periodo = ''
        this.cantMeses = ''
      }
    },
    ptovtas: function(){
      if (this.ptovtas.length > 1) this.importe = ''
    },
    periodo: function(){
      if (this.periodo == null || this.periodo == undefined) this.periodo = ''
    }
  },
}
</script>

<style>

</style>