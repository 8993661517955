<template>
  <v-dialog
    v-model="dialog"
    max-width="650"
    :persistent="load"
    scrollable
  >
    <v-card>
      <v-card-title>
        Buscar empresa
        <v-spacer></v-spacer>
        <v-btn
          :disabled="load"
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-form @submit.prevent="buscar()">
          <v-row class="mx-4 mt-0 mb-3">
            <v-col cols="12" md="4" class="py-1">
              Razón social
              <v-text-field
                v-model.trim="nombre"
                hide-details
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="py-1">
              CUIT
              <v-text-field
                v-model.trim="cuit"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="d-flex justify-end align-end py-1">
              <v-btn
                color="info"
                type="submit"
                :loading="load"
              >
                <v-icon left>fas fa-search</v-icon>
                Buscar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-data-table
          v-model="selected"
          checkbox-color="primary"
          :headers="headers"
          :items="empresas"
          :loading="load"
          single-select
          show-select
          dense
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="indigo"
              title="Editar"
              small
              @click="open_dialog(item)"
            >
              fas fa-pen
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          :disabled="selected.length != 1"
          @click="seleccionar()"
        >
          <v-icon left>fas fa-check</v-icon>
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
    <ModificarEmpresaGasto
      v-model="dialog_editar"
      :p_empresa="empresa"
      @editar="editar"
    />
  </v-dialog>
</template>

<script>
/**
 *  Modal para buscar empresas por razon social. Cuando selecciona una emite la accion @seleccion
 *  al componente padre con el cuit y nombre de la empresa seleccionada
 *  tambien permite modificar el cuit o razon social de una emrpesa
 */
import ModificarEmpresaGasto from './ModificarEmpresaGasto'

export default {
  data () {
    return {
      load: false,
      dialog_editar: false,
      nombre: '',
      cuit: '',
      empresa: {},
      selected: [],
      empresas: [],
      headers: [
        { text: 'Razón social', value: 'nombre' },
        { text: 'CUIT', value: 'cuit' },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false }
      ]
    }
  },
  props: {
    value: Boolean
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    ModificarEmpresaGasto
  },
  watch: {
    dialog (val) {
      val || this.clear()
    }
  },
  methods: {
    editar (empresa_editada) {
      let empresa = this.empresas.find(e => e.id == empresa_editada.id)
      Object.assign(empresa, empresa_editada)
    },
    async buscar () {
      if (!this.nombre && !this.cuit) {
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar una razón social o un CUIT',
          color: 'warning'
        })
        return
      }
      this.load = true
      this.selected = []
      this.empresas = []
      await this.$store.dispatch('controlCaja/get_gastos_empresas', { nombre: this.nombre, cuit: this.cuit })
        .then((res) => {
          this.empresas = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    seleccionar () {
      this.$emit('seleccion', this.selected[0])
      this.dialog = false
    },
    open_dialog (item) {
      this.empresa = item
      this.dialog_editar = true
    },
    clear () {
      this.nombre = ''
      this.cuit = ''
      this.selected = []
      this.empresas = []
    }
  }
}
</script>